import { useEffect, useState } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import platform from 'platform'
import { goTo, isClient, getDevice } from 'utils'

import services from './services'

const useSmsCodeVerification = ({ setValue, trigger, resetField }) => {
  const [isIOS, setIsIOS] = useState(false)
  const tokenLength = 4
  const { setIsLoading } = useProposal()
  const [submitLoading, setSubmitLoading] = useState(false)
  const { os } = platform
  const { changeLeadData } = useLead()

  const [onLoadData, setOnLoadData] = useState({
    phone: ''
  })

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-sms-otp' })
  }, [])

  useEffect(() => {
    const getPlatform = () => {
      const isMobile = getDevice.isMobile()

      if (!isMobile) return setIsIOS(false)

      setIsIOS(os.family === 'iOS')
    }

    getPlatform()
  }, [os.family])

  const sendSms = async () => {
    return await services.postSendSms()
  }

  const getData = async () => {
    try {
      const { lead_phone_number } = await http.proposal.getLeadPhone({
        removeHash: true
      })

      const { previous_step } = await services.getSmsToken()
      changeLeadData({
        previousStep: previous_step
      })
      setOnLoadData({
        phone: lead_phone_number,
        prevStep: previous_step
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async (payload) => {
    setSubmitLoading(true)

    const { token } = payload

    const requestBody = {
      sms_code: token
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-sms-otp',
      detail: 'click-step-otp-sms'
    })

    return services
      .postSmsToken(requestBody)
      .then(async () => {
        return services
          .getStepInfo()
          .then(({ next_step, final_screen }) => {
            if (final_screen) return goTo(final_screen)

            goTo(next_step)
          })
          .finally(() => setSubmitLoading(false))
      })
      .finally(() => setSubmitLoading(false))
  }

  const getTokenFromSmsAndroid = (token) => {
    if (token?.length === tokenLength) {
      setValue('token', token)
      trigger('token')
      onSubmit({ token })
    }
  }

  const getTokenFromSmsIos = (token) => {
    if (token?.length === tokenLength) {
      resetField('token', { defaultValue: token })
      onSubmit({ token })
    }
  }

  useEffect(() => {
    setIsLoading(true)

    if (isClient()) {
      if ('OTPCredential' in window) {
        const ac = new AbortController()
        navigator.credentials
          .get({
            otp: { transport: ['sms'] },
            signal: ac.signal
          })
          .then((otp) => getTokenFromSmsAndroid(otp.code))
          .catch((err) => console.log(`err: ${err}`))
      }
    }

    getData()

    console.log('sms verification mounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tokenValidation = {
    rules: {
      validate: {
        isNumber: (value) => {
          if (isNaN(value)) return false
        }
      }
    },
    messages: {
      isNumber: 'O campo só deve conter números.'
    }
  }

  const onPrevStepClick = () => {
    goTo(onLoadData.prevStep)
  }

  return {
    onLoadData,
    onSubmit,
    tokenValidation,
    getTokenFromSmsIos,
    sendSms,
    onPrevStepClick,
    submitLoading,
    isIOS
  }
}

export default useSmsCodeVerification
